import logo from './logo.svg';
import './App.css';
import Navbar from './navbar';
import Profile3D from './portfoilo';

function App() {
  return (
    <>
   <Navbar/>
   <Profile3D/>

    </>
  );
}

export default App;
