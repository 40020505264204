import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";

const HeaderButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const ProfileImage = styled("img")({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  transition: "transform 1s",
  "&:hover": {
    transform: "rotateY(360deg)",
  },
});

export default function Navbar() {
  return (
    <>
      {/* Header */}
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar
          sx={{ justifyContent: "space-between", padding: "0 20px", flexWrap: "wrap" }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">kawsar.design@gmail.com</Typography>
            <HeaderButton
              variant="outlined"
              size="small"
              onClick={() =>
                navigator.clipboard.writeText("kawsar.design@gmail.com")
              }
            >
              Copy
            </HeaderButton>
            <Link href="#" underline="none" variant="body2" sx={{ marginLeft: 2 }}>
              CV
            </Link>
          </Box>
          <Box>
            <Link href="#" underline="none" variant="body2" sx={{ marginRight: 2 }}>
              LinkedIn
            </Link>
            <Link href="#" underline="none" variant="body2" sx={{ marginRight: 2 }}>
              Dribbble
            </Link>
            <Link href="#" underline="none" variant="body2">
              Instagram
            </Link>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container
        maxWidth="md"
        sx={{ textAlign: "center", marginTop: "50px", padding: "20px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ProfileImage
            src="https://via.placeholder.com/100"
            alt="Profile"
          />
          <Typography variant="h6" sx={{ marginTop: "10px" }}>
            Kawsar Ahmed <span role="img" aria-label="wave">👋</span>
          </Typography>
        </Box>
        <Typography variant="h3" sx={{ marginTop: 3, fontWeight: 500 }}>
          Building digital <strong>products</strong>, <strong>brands</strong>, and
          experience.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginTop: 3, borderRadius: 2 }}
        >
          Latest Shots <Box component="span" sx={{ marginLeft: 1 }}>2</Box>
        </Button>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          textAlign: "center",
          marginTop: "50px",
          padding: "20px",
          borderTop: "1px solid #ddd",
        }}
      >
        <Link href="#" underline="none" sx={{ marginRight: 2 }}>
          LinkedIn
        </Link>
        <Link href="#" underline="none" sx={{ marginRight: 2 }}>
          Dribbble
        </Link>
        <Link href="#" underline="none">
          Instagram
        </Link>
      </Box>
    </>
  );
}
