import React from "react";
import { Canvas } from "@react-three/fiber";

const Profile3D = () => {
  return (
    <Canvas style={{ height: "200px", margin: "auto" }}>
      <ambientLight intensity={0.5} />
      <mesh rotation={[0.5, 0.5, 0]} castShadow>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="blue" />
      </mesh>
    </Canvas>
  );
};

export default Profile3D;
